<template>
  <b-table hover :items="blogs" :fields="fields">
    <template #cell(thumb_url)="data">
      <img :src="data.item.thumb_url" style="width: 160px;" alt="">
    </template>
    <template #cell(title)="data">
      <b-link :href="`/blog/${data.item.url}`" target="_blank">{{data.item.title}}</b-link>
    </template>
    <template #cell(actions)="data">
      <b-button :href="`edit/${data.item.id}`" variant="link">Edit</b-button>
      <b-button @click="showBlog(data.item.id)" variant="link">Show</b-button>
    </template>
  </b-table>
</template>

<script>
import api from '@/api'

export default {
  data: () => ({
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'thumb_url',
        label: 'Thumbnail'
      },
      {
        key: 'title',
        label: 'Title',
        sortable: true
      },
      {
        key: 'date_published',
        label: 'Published Date',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    blogs: []
  }),
  mounted () {
    this.getHiddenBlogs()
  },
  methods: {
    getHiddenBlogs: async function () {
      let blogs = await api.admin.blog.getHiddenBlogs()
      if (blogs) {
        this.blogs = blogs
      }
    },
    showBlog: async function (blog_id) {
      let r = await api.admin.blog.setBlogActive(blog_id, true)
      if (r) {
        this.$router.go()
      }
    }
  }
}
</script>

<style scoped>
</style>
